import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import LogoSection from "../../components/LogoSection";
import ResponsiveTitle from "../../components/ResponsiveTitle";
import { resetCart } from "../../state/cart";
import OrderSummary from "./OrderSummary";

const { Paragraph } = Typography;

const CheckoutSuccess = ({
  location: {
    state: { order, shouldResetCart },
  },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkoutType, setCheckoutType] = useState();

  useEffect(() => {
    if (shouldResetCart) dispatch(resetCart());
  }, [dispatch, shouldResetCart]);

  useEffect(() => {
    if (!order) navigate("/");
    else {
      if (order.orderNo) setCheckoutType("orders");
      if (order.consolidationOrderNo) setCheckoutType("consolidationOrders");
    }
  }, [order]);

  if (!order) return null;

  return (
    <Row justify="center">
      <Col>
        <Space direction="vertical" style={{ width: "100%" }}>
          <ResponsiveTitle>{t("success.thankYou")}</ResponsiveTitle>
          <Paragraph>
            {checkoutType === "orders"
              ? t("success.confirm")
              : t("success.consolidationConfirm")}
          </Paragraph>
          <Row justify="center">
            <Space direction="vertical" style={{ width: "100%" }}>
              <OrderSummary order={order} />
              <Button
                size="large"
                onClick={() => navigate(`app/${checkoutType}/${order.id}`)}
              >
                {t("success.viewOrder")}
              </Button>
            </Space>
          </Row>
        </Space>
      </Col>
      <Col>
        <LogoSection />
      </Col>
    </Row>
  );
};

export default CheckoutSuccess;
