import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import Logo from "../images/logos/marigold.svg";
import styles from "./css/logoSection.module.scss";

const LogoSection = () => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const { t } = useTranslation();
  return (
    <>
      <img
        src={Logo}
        className={styles.logo}
        style={{ width: isMobile && 50, marginBottom: "10px" }}
        alt="logo"
      />
      <br />
      <br />
      <p style={{ color: "#dddddd", fontSize: "10pt", textAlign: "center" }}>
        {t("logoSection.copyright")}
        <span style={{ fontSize: "12pt" }}>
          <ReactCountryFlag countryCode="SG" svg />{" "}
          <ReactCountryFlag countryCode="AU" svg />{" "}
        </span>
        <br />
        {t("logoSection.rights")}
      </p>
    </>
  );
};

export default LogoSection;
